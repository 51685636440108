import React, { useContext, useEffect } from "react";

import { StateContext } from "../state/StateProvider";
import Layout from "../components/layout";
import SEO from "../components/seo";

function TermsofServicePage() {
  const { isDark, setTheme } = useContext(StateContext);
  useEffect(() => {
    setTheme(false);
  }, []);
  return (
    <Layout>
      <SEO
        keywords={[`sparkrise`, `terms of service`]}
        title="Terms of Service"
      />
      <article className="mt-24">
        <heading className="container">
          <h2 className="px-4 text-center text-black c-h3">Terms of Service</h2>
        </heading>
        <div className="px-4 mx-auto prose lg:w-8/12 max-w-none">
          <body lang="en-US" link="#0563c1" vlink="#954f72" dir="ltr">
            <p>
              By visiting this website (the “Site”) and engaging with its
              functions (each a “Service”) you implicitly agree to be bound by
              the following Terms of Service (this “Agreement”):
            </p>
            <p>
              {" "}
              <b>GENERAL RESTRICTIONS ON USE:</b>
            </p>
            <p>
              (a)&nbsp;SparkRaiser, Inc., a Delaware corporation (DBA SparkRise®)
              (the “Company”) grants you a limited license to access its
              Services and not to download (other than page caching or
              permissible downloadable Sponsor Campaign offers (the “Offers”) or
              modify it (or any portion thereof) except with express written
              consent of the Company. This license does not include any resale,
              publication or commercial use of the Site or its contents, any
              collection and use of any product listings, descriptions, or
              prices, any derivative use of this site or its contents, any
              downloading or copying of account information for the benefit of
              another merchant, or any use of data mining, robots, or similar
              data gathering, reverse engineering and extraction tools.
            </p>
            <p>
              (b)&nbsp;You agree not to use any device, software or other
              instrumentality to interfere or attempt to interfere with the
              proper working of the Company’s Site or Service. You will not take
              any action that imposes an unreasonable or disproportionately
              large load on the Site’s infrastructure. You agree not to use any
              robot, spider, other automatic device, or manual process to
              monitor or copy any content from the Site without the prior
              express consent from an authorized Company representative, unless
              such use is by a search engine employed to direct Internet users
              to the Site or Service.
            </p>
            <p>
              (c)&nbsp;The Site or Services or any portion thereof may not be
              reproduced, duplicated, copied, sold, resold, visited, or
              otherwise exploited for any commercial purpose without express
              written consent of the Company. You may not frame or utilize
              framing techniques to enclose any trademark, logo, or other
              proprietary information (including images, text, page layout, or
              form) of the Company or its associates without express written
              consent. You may not use any meta tags or any other “hidden” text
              utilizing the Company’s name or trademarks without the express
              written consent of the Company.
            </p>
            <p>
              (d)&nbsp;Any unauthorized use terminates the permission or license
              granted by the Company. You are granted a limited, revocable, and
              nonexclusive right to create hyperlinks to the Site, including for
              purposes of conducting Campaigns, so long as the link does not
              portray the Company, its associates, or their products or services
              in a false, misleading, derogatory, or otherwise offensive matter.
            </p>
            <p>
              {" "}
              <b>INTELLECTUAL PROPERTY.&nbsp;</b>The Site and Services include
              certain content, such as text, graphics, logos, button icons,
              images, audio and video clips, data compilations, business models
              and software, that is the property of the Company or its content
              suppliers and protected by international copyright laws. The Site
              and Services may contain or reference trademarks, patents,
              copyrighted materials, trade secrets, technologies, products,
              processes or other proprietary rights belonging to Company and/or
              other parties. No license to or right in any such trademarks,
              patents, copyrighted materials, trade secrets, technologies,
              products, processes and other proprietary rights of the Company
              and/or other parties is granted to or conferred upon you or any
              other user.
            </p>
            <p>
              To notify the Company of any copyright-infringing content, please
              contact us at&nbsp;
              <a href="mailto:support@SparkRise.com">support@SparkRise.com</a>.
            </p>
            <p>
              {" "}
              <b>COMMUNICATIONS.</b>&nbsp;The Company shall be free to
              reproduce, use, disclose, and distribute any and all communication
              conducted with the Company through the Services including but not
              limited to feedback, questions, comments, suggestions and the like
              (the “Communications”). You shall have no right of confidentiality
              in the Communications and the Company shall have no obligation to
              protect the Communications from disclosure. The Company shall be
              free to use any ideas, concepts, know-how, content or techniques
              contained in the Communications for any purpose whatsoever,
              including but not limited to the development, production and
              marketing of products and services that incorporate such
              information.
            </p>
            <p>
              {" "}
              <b>LIMITED LIABILITY.</b>&nbsp;UNDER NO CIRCUMSTANCES SHALL THE
              COMPANY BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY INDIRECT,
              INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES FOR ANY
              MATTER ARISING FROM OR RELATING TO THIS AGREEMENT, THE SITE, ANY
              SERVICE OFFERED BY THE COMPANY, INCLUDING, BUT NOT LIMITED TO: (A)
              ANY PARTY’S USE OR INABILITY TO USE THE SITE OR SERVICES; (B) ANY
              CHANGES TO OR INACCESSIBILITY OF THE SITE OR SERVICES; (C) ANY
              DELAY, FAILURE, UNAUTHORIZED ACCESS TO OR ALTERATION OF ANY DATA
              OR ANY TRANSMISSION OF DATA; (D) ANY CONTENT OR DATA TRANSMITTED
              OR RECEIVED (OR NOT TRANSMITTED OR RECEIVED) BY/FROM ANY PARTY; OR
              (E) ANY CONTENT OR DATA FROM A THIRD PERSON ACCESSED ON OR THROUGH
              THE SITE, OR THE SERVICE; WHETHER SUCH LIABILITY IS ASSERTED ON
              THE BASIS OF CONTRACT, TORT OR OTHERWISE.
            </p>
            <p>
              {" "}
              <b>WARRANTY DISCLAIMER. </b>THIS SITE IS PROVIDED “AS IS” WITHOUT
              WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. USE OF THIS SITE AND
              ANY SERVICE OFFERED BY THE COMPANY IS AT YOUR SOLE RISK. THE
              COMPANY MAKES NO WARRANTIES, INCLUDING, BUT NOT LIMITED TO: (A)
              THAT THE SITE OR THE SERVICES WILL MEET YOUR REQUIREMENTS; (B)
              THAT THE SITE WILL BE SECURE, UNINTERRUPTED, ACCESSIBLE OR
              ERROR-FREE; OR (C) THAT ANY INFORMATION, DATA OR CONTENT OBTAINED
              FROM THE SITE OR THE SERVICES WILL BE ACCURATE, RELIABLE,
              COMPLETE, TIMELY OR FREE FROM VIRUSES OR OTHER FORMS OF
              DESTRUCTIVE CODE.
            </p>
            <p>
              {" "}
              <b>INDEMNIFICATION.&nbsp;</b>You agree to indemnify, hold harmless
              and defend the Company, along with its stockholder, director,
              officers, employees and agents from and against any action, cause,
              claim, damage, debt, demand or liability, including reasonable
              costs and attorney’s fees, asserted by any person or entity,
              arising out of or relating to: (i) this Agreement and/or any
              breach or threatened breach by you; (ii) your use of the Site or
              any service offered by the Company; (c) any actions taken based on
              the Site’s content; (d) any unacceptable or objectionable use of
              the Site or any service offered to you by the Company; or (e) any
              negligent or willful misconduct by you.
            </p>
            <p>
              {" "}
              <b>ERRORS AND OMISSIONS.&nbsp;</b>The Site and Services may
              contain technical inaccuracies and typographical errors. The
              Company shall not assume responsibility or liability for any such
              inaccuracies, errors or omissions, and shall have no obligation to
              correct information affected by such inaccuracies. The Company
              reserves the right to make changes, corrections, cancellations or
              improvements to any information contained on the Site, and to the
              products and programs described in such information, at any time
              without notice.
            </p>
            <p>
              {" "}
              <b>THIRD PARTY LINKS.</b>&nbsp;There may from time to time be
              links on the Site that lead to third party sites, including those
              of advertisers. THE COMPANY IS NOT RESPONSIBLE FOR THE PRIVACY
              POLICIES OF THOSE SITES OR THE COOKIES THOSE SITES USE. IN
              ADDITION, BECAUSE THE COMPANY HAS NO CONTROL OVER SUCH SITES AND
              RESOURCES, YOU ACKNOWLEDGE AND AGREE THAT THE COMPANY IS NOT
              RESPONSIBLE FOR THE AVAILABILITY OF SUCH EXTERNAL SITES OR
              RESOURCES, AND DOES NOT ENDORSE AND IS NOT RESPONSIBLE OR LIABLE
              FOR ANY CONTENT, ADVERTISING, PRODUCTS, OR OTHER MATERIALS ON OR
              AVAILABLE FROM SUCH SITES OR RESOURCES.
            </p>
            <p>
              {" "}
              <b>MODIFICATION.</b>&nbsp;The Company has the right at any time or
              from time to time to modify or amend this Agreement. Should the
              Company choose to modify this Agreement the Site will display such
              changes, which will be your only notification of any such change.
              Any use of the Site or the Services by you after such notification
              shall constitute your acceptance of the modified or amended terms.
              No modification to this Agreement made or attempted to be made by
              you shall be binding upon the Company.
            </p>
            <p>
              {" "}
              <b>SEVERABILITY.&nbsp;</b>If any provision of this Agreement is
              held to be invalid, illegal, or unenforceable for any reason, such
              invalidity, illegality or unenforceability shall not affect any
              other provisions of this Agreement, and this Agreement shall be
              construed as if such invalid, illegal or unenforceable provision
              had not been contained herein.
            </p>
            <p>
              {" "}
              <b>
                DISPUTE RESOLUTION BY BINDING ARBITRATION AND CLASS ACTION
                WAIVER.{" "}
              </b>
              If a dispute arises in connection with your use of our Site or
              Services or in relation to any of these Terms of Service
              (collectively, “Disputes”) our hope is that we can resolve the
              matter informally. Accordingly, in the event of a Dispute, we
              agree to first contact each other via email with a description of
              the Dispute and any proposed resolution. You will email{" "}
              <a href="mailto:support@SparkRise.com">support@SparkRise.com </a>
              with your concern and the Company will contact you via the email
              address we have on file for you.&nbsp;
            </p>
            <p>
              If a Dispute cannot be resolved informally, we each agree that
              except as provided below, the Dispute will be submitted to final
              and binding arbitration in Los Angeles, CA before one arbitrator.
              The arbitration shall be administered by JAMS pursuant to its
              Streamlined Arbitration Rules and Procedures. Please refer to{" "}
              <a href="http://www.jamsadr.com/rules-streamlined-arbitration/">
                www.jamsadr.com/rules-streamlined-arbitration/
              </a>{" "}
              for details on this process. Either party may commence the
              arbitration process by submitting a written demand for arbitration
              with JAMS and providing a copy to the other party. Judgment on the
              award rendered by the arbitrator may be entered in any court of
              competent jurisdiction.{" "}
              <b>
                You expressly agree to refrain from bringing or joining any
                claims in any representative or class-wide capacity, including
                but not limited to bringing or joining any claims in any class
                action or any class-wide arbitration
              </b>
              .
            </p>
            <p>
              {" "}
              <b>
                YOU UNDERSTAND THAT YOU ARE WAIVING YOUR RIGHT TO HAVE A JUDGE
                OR JURY DECIDE YOUR CASE AND TO BE PARTY TO A CLASS OR
                REPRESENTATIVE ACTION. YOU UNDERSTAND AND AGREE TO HAVE ANY
                CLAIMS DECIDED INDIVIDUALLY AND ONLY THROUGH ARBITRATION.
              </b>
            </p>
            <p>
              {" "}
              <b>EXCEPTIONS</b>: Notwithstanding the foregoing, the following
              will not be subject to arbitration and may be adjudicated only in
              the state and federal courts of Los Angeles County: (i) any
              dispute, controversy, or claim related to or contesting the
              validity of our proprietary rights, including without limitation,
              trademarks, service marks, copyrights, patents, or trade secrets;
              or (ii) an action by a party for temporary, preliminary, or
              permanent injunctive relief, whether prohibitive or mandatory, or
              other provisional relief. You may also file an individual action
              in a small claims court in lieu of arbitration.
            </p>
            <p>
              {" "}
              <b>OPTING OUT:</b> You will have thirty (30) days from the date
              you submit your personally identifiable information to opt out of
              this arbitration agreement. To opt out of arbitration, you must
              contact us in writing at 4361 Melrose Avenue, Los Angeles,
              California 90029, Attention: SparkRise® Legal Department. If more
              than thirty (30) days have passed, you are not eligible to opt out
              of arbitration.
            </p>
            <p>
              {" "}
              <b>YOUR AGREEMENT TO THESE TERMS</b>
            </p>
            <p>
              You acknowledge and agree that by entering into this Agreement
              electronically you are expressly agreeing to the terms set forth
              herein. You acknowledge that your electronic submission
              constitutes your agreement and intent to be bound by this
              Agreement.&nbsp;
            </p>
            <p>
              {" "}
              <b>EVERYTHING ELSE</b>
            </p>
            <p>
              If any provision in this Agreement is found to be invalid,
              unenforceable, or nonsensical, the remaining provisions will
              continue in full force and effect. This agreement is and always
              will be governed by the laws of the United States of America and
              the State of California (except with respect to choice of law).
              You agree that the proper forum for any claim arising hereunder
              will be the federal or state courts in Los Angeles County.
            </p>
            <p>
              Our Site and Services are directed at a U.S. audience. We cannot
              warrant that the Services are appropriate for users outside the
              United States or that use of the Services is permitted under the
              laws of other jurisdictions. All personal data is maintained in
              the United States under the terms of our{" "}
              <a href="/privacypolicy">Privacy Policy</a>, which is also
              incorporated by reference.
            </p>
            <p>There are no third-party beneficiaries to this Agreement.</p>
            <p>
              This Agreement, including those terms that are incorporated by
              reference, constitute the entire and only agreement between you
              and the Company and govern your use of our Site.
            </p>
            <p>
              For information please contact:&nbsp;
              <a href="mailto:support@SparkRise.com">support@SparkRise.com</a>
            </p>
          </body>
        </div>
      </article>
    </Layout>
  );
}

export default TermsofServicePage;
